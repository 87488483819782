<template>
  <div class="relative" v-click-outside="handleClose">
    <slot name="toggle" :toggle="handleToggle" />
    <div
      :class="[
        'absolute right-0 top-12 z-10 w-full divide-y divide-gray-100 overflow-y-auto rounded bg-white shadow',
      ]"
      :style="{ width: width, top: top }"
    >
      <slot v-if="visible" name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: null,
    },
    top: String
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    handleShow() {
      this.visible = true;
    },
    handleClose() {
      this.visible = false;
    },
    handleToggle() {
      this.visible = !this.visible;
    },
  },
};
</script>
