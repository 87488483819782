export function loadScript(url) {
  if (!url) {
    return new Error('url diperlukan');
  }
  const script = document.createElement('script');
  script.src = url;
  script.type = 'text/javascript';
  document.head.appendChild(script);
  script.onload = () => {
    console.log('External script loaded successfully');
  };
  script.onerror = () => {
    return new Error(`script ${url} gagal dijalanakn`);
  };
}

export function loadStyle(url) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  document.head.appendChild(link);
  link.onload = () => {
    console.log('External CSS loaded successfully');
  };
  link.onerror = () => {
    return new Error(`script ${url} gagal dijalanakn`);
  };
}
