<template>
  <div>
    <div class="space-y-4">
      <div
        class="relative flex w-full flex-row gap-4"
        v-for="(payment, index) in payments"
        :key="`metode-${index}`"
      >
        <div class="w-full">
          <div class="field-inset-default relative">
            <label
              for="metode_pembayaran"
              class="block text-xs font-bold text-gray-700"
            >
              Metode Pembayaran
            </label>
            <select
              v-model="payment.type"
              @change="handleChangePaymentType"
              type="text"
              name="metode_pembayaran"
              id="metode_pembayaran"
              class="input-inset-select"
              placeholder="Pilih Jenis Pembayaran"
            >
              <option :value="null">Pilih Metode Pembayaran</option>
              <option
                v-for="method in methods"
                :disabled="method.disabled"
                :key="method.id"
                :value="{
                  id: method.id,
                  name: method.name,
                  value: method.value,
                }"
              >
                {{ method.renderName ? method.renderName() : method.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full" v-if="checkIsMethodUsingBank(payment.type)">
          <div class="field-inset-default relative">
            <label
              for="pilih_bank"
              class="block text-xs font-bold text-gray-700"
            >
              Pilih Bank
            </label>
            <select
              v-model="payment.bank"
              @change="handleChangeBank"
              type="text"
              name="pilih_bank"
              id="pilih_bank"
              class="input-inset-select"
              placeholder="Pilih Jenis Pembayaran"
            >
              <option :value="null">Pilih Bank</option>
              <option
                v-for="officeBank in officeBanks"
                :key="officeBank.id"
                :value="{
                  id: officeBank.id,
                  name: officeBank.bank_name,
                }"
              >
                {{ officeBank.attributes.bank_name }} (a.n
                {{ officeBank.attributes.account_name }}) ({{
                  officeBank.attributes.account_number
                }})
              </option>
            </select>
          </div>
        </div>
        <div
          class="w-full"
          v-if="checkIsMethodUsingReceiptNumber(payment.type) && payment.bank"
        >
          <div class="field-inset-default">
            <label class="block text-xs font-bold text-gray-700">
              Nomor Struk
            </label>
            <input
              type="text"
              class="input-inset-select"
              placeholder="Masukkan Nomor Struk"
              v-model="payment.receipt_number"
            />
          </div>
        </div>
        <base-input
          v-if="checkIsMethodUsingBank(payment.type)"
          label="Tanggal"
          with-label
          inset
          type="datetime-local"
          :classes="{
            input: 'pt-0',
          }"
          v-model="payment.transfer_date"
        />
        <div class="w-full" v-if="checkIsMethodUsingDescription(payment.type)">
          <div class="field-inset-default">
            <label class="block text-xs font-bold text-gray-700">
              Keterangan
            </label>
            <input
              type="text"
              class="input-inset-select"
              placeholder="Masukkan Keterangan"
              v-model="payment.description"
            />
          </div>
        </div>
        <div
          class="relative w-full"
          v-if="
            payment.type &&
            (!checkIsMethodUsingBank(payment.type) || payment.bank) &&
            (!checkIsMethodUsingReceiptNumber(payment.type) ||
              payment.receipt_number)
          "
        >
          <div class="field-inset-default">
            <label for="nominal" class="block text-xs font-bold text-gray-700">
              Nominal Pembayaran
            </label>
            <input
              ref="nominal"
              @change="handleChangeNominal(index, payment)"
              @input="(e) => handleChangePaymentValue(e, index)"
              type="text"
              :value="toCurrency(payment.value)"
              name="nominal"
              id="nominal"
              class="input-inset-select"
              placeholder="Masukkan Nominal Pembayaran"
            />
          </div>
        </div>

        <button
          v-if="
            withBankUpload &&
            checkIsMethodUsingBank(payment.type) &&
            payment.bank
          "
          @click="handleUploadTransfer(index)"
          class="flex items-center rounded-md border border-gray-300 bg-white px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white"
          title="Upload bukti pembayaran"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-4 w-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
            />
          </svg>
        </button>

        <button
          v-if="withDeleteMethod"
          @click="handleRemovePayment(index, payment)"
          type="button"
          class="focus:outline-none absolute inset-y-0 -top-2 -right-2 flex h-5 w-5 items-center rounded-full border border-transparent bg-red-300 p-1 text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div class="flex justify-end space-x-2">
        <slot name="action" />
        <button
          v-if="withAppendMethod"
          @click="handleAddPayment"
          type="button"
          class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
        >
          Tambah Metode
        </button>
      </div>
    </div>

    <BaseModal
      :showing="uploadTransferModal.visible"
      @close="handleCloseUploadTrasfer"
      :showClose="false"
      :backgroundClose="true"
      size="max-w-lg"
    >
      <div class="space-y-4" v-if="uploadTransferModal.visible">
        <h2 class="text-lg font-bold text-gray-900">Bukti File</h2>
        <span class="text-sm text-gray-500">wajib bertipe jpg, png</span>
        <base-file-upload
          class="rounded-lg border p-4 pb-0"
          v-model="payments[uploadTransferModal.paymentIndex].files"
        />
        <div class="flex justify-end">
          <base-button color="white" @click="handleCloseUploadTrasfer"
            >Tutup</base-button
          >
        </div>
      </div>
    </BaseModal>
    <span
      class="text-xs font-light italic text-red-600"
      v-if="!officeBanks.length"
    >
      Untuk saat ini kantor asal tidak mempunyai rekening tersedia
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/components/base/BaseModal';
import BaseInput from '@/components/base/BaseInput';
import BaseButton from '@/components/base/BaseButton';
import BaseFileUpload from '@/components/base/BaseFileUpload.vue';
import { paymentMethodFormMixin } from '@/mixins/payment-method/payment-method-form.mixin';

export default {
  mixins: [paymentMethodFormMixin],
  props: {
    value: {
      type: Array,
      required: true,
    },
    withBankUpload: {
      type: Boolean,
      default: true,
    },
    withAppendMethod: {
      type: Boolean,
      default: true,
    },
    withDeleteMethod: {
      type: Boolean,
      default: true,
    },
    autoStore: {
      type: Boolean,
      default: false,
    },
    paymentId: null,
  },
  components: { BaseModal, BaseButton, BaseFileUpload, BaseInput },
  emits: [
    'input',
    'remove-payment',
    'change-nominal',
    'change-method',
    'change-bank',
    'change-payment-type',
  ],
  data() {
    return {
      uploadTransferModal: {
        visible: false,
        paymentIndex: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getBanksByOffice: 'banks/getBanksByOffice',
      getPaymentMethodTypes: 'payment_method_types/getPaymentMethodTypes',
    }),
    payments: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    officeBanks() {
      return this.getBanksByOffice.data.filter(
        (bank) => bank.attributes.office_bank_type === 'bank'
      );
    },
    methods() {
      return this.getPaymentMethodTypes.data.map((type) => {
        const disabled = this.checkIsMethodDisabled(type.attributes.name);

        return {
          id: type.id,
          name: disabled
            ? `${type.attributes.name} (Belum ada bank yang ditambahkan)`
            : type.attributes.name,
          value: type.id,
          disabled,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      createPaymentMethod: 'payment_methods/createPaymentMethod',
      updatePaymentMethod: 'payment_methods/updatePaymentMethod',
      deletePaymentMethod: 'payment_methods/deletePaymentMethod',
    }),
    toCurrency(num) {
      return this.$options.filters.toCurrency(num);
    },
    handleChangePaymentValue(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.payments[index].value = value;

      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    handleChangePaymentType() {
      this.$emit('change-payment-type');
    },
    handleChangeBank() {
      this.$emit('change-bank');
    },
    handleChangeNominal(index, payment) {
      if (this.autoStore) {
        this.storePayment(index, payment);
      }

      this.$emit('change-nominal', payment);
    },
    handleRemovePayment(index, payment) {
      this.$emit('remove-payment', payment);

      if (this.autoStore && payment.id) {
        this.deletePayment(index, payment);
      } else {
        this.payments.splice(index, 1);
      }
    },
    handleUploadTransfer(index) {
      this.uploadTransferModal.paymentIndex = index;
      this.uploadTransferModal.visible = true;
    },
    handleCloseUploadTrasfer() {
      this.uploadTransferModal.paymentIndex = null;
      this.uploadTransferModal.visible = false;
    },
    handleAddPayment() {
      this.payments.push({
        type: null,
        bank: null,
        value: null,
        description: null,
        files: [],
      });
    },
    async storePayment(index, paymentMethod) {
      const payload = this.getPayload(paymentMethod);

      if (!paymentMethod.id) {
        const res = await this.createPaymentMethod({
          paymentId: this.paymentId,
          officeBankId: payload.relationships['office-bank'].data.id,
          attributes: payload.attributes,
          paymentMethodTypeId:
            payload.relationships['payment-method-type'].data.id,
        });

        if (res) {
          this.payments[index].id = res.data.data.id;
        }
      } else {
        const res = await this.updatePaymentMethod({
          paymentId: this.paymentId,
          paymentMethodId: paymentMethod.id,
          officeBankId: payload.relationships['office-bank'].data.id,
          attributes: payload.attributes,
          paymentMethodTypeId:
            payload.relationships['payment-method-type'].data.id,
        });

        if (res) {
          this.payments[index].id = res.data.data.id;
        }
      }
    },
    async deletePayment(index, paymentMethod) {
      const res = await this.deletePaymentMethod({
        paymentMethodId: paymentMethod.id,
      });

      if (res) {
        this.payments.splice(index, 1);
      }
    },
  },
};
</script>
