<template>
  <section class="w-full">
    <router-view />
  </section>
</template>
<script>
// import Breadcrumbs from '@/components/Breadcrumbs.vue'
export default {
  name: 'LayoutData',
  components: {
    // Breadcrumbs
  },
};
</script>
