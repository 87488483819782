<template>
  <div>
    <span ref="input" type="file" multiple></span>
  </div>
</template>

<script>
import * as FilePond from 'filepond';
import { loadStyle } from '../../services/script.service';

export default {
  name: 'BaseFileUpload',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  emits: ['input'],
  computed: {
    files: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleChangeFiles(files) {
      this.files = files.map((file) => file.file);
    },
  },
  mounted() {
    loadStyle('https://unpkg.com/filepond@^4/dist/filepond.min.css');
    if (this.$refs.input) {
      FilePond.create(this.$refs.input, {
        files: this.files.map((file) => ({
          options: {
            type: 'local',
            file: {
              name: file.name,
              size: file.size,
              type: file.type,
            },
          },
        })),
        onupdatefiles: (files) => {
          this.handleChangeFiles(files);
        },
      });
    }
  },
};
</script>

<style>
.filepond--credits {
  display: none !important;
  visibility: none !important;
}
</style>
