import { mapGetters } from 'vuex';
import { sum } from '@/utils/array';
import dayjs from 'dayjs';

export const paymentMethodFormMixin = {
  data() {
    return {
      paymentMethodsForm: [],
    };
  },
  computed: {
    ...mapGetters({
      getPaymentMethods: 'payments/getPaymentMethods',
      getPaymentMethodTypes: 'payment_method_types/getPaymentMethodTypes',
      getBanksByOffice: 'banks/getBanksByOffice',
    }),
    paymentMethodTypes() {
      return this.getPaymentMethodTypes.data.map((type) => ({
        id: type.id,
        name: type.attributes.name,
        value: type.id,
      }));
    },
    totalPaymentAmount() {
      return sum(this.paymentMethodsForm, (payment) => payment.value);
    },
  },
  methods: {
    checkIsMethodUsingBank(type) {
      if (!type) {
        return false;
      }

      const methodsUsingBank = ['Transfer Bank', 'EDC'];

      return methodsUsingBank.includes(type.name);
    },
    checkIsMethodUsingDescription(type) {
      if (!type) {
        return false;
      }

      const methodsUsingDescription = ['other'];

      return methodsUsingDescription.includes(type.value);
    },
    checkIsMethodUsingReceiptNumber(type) {
      if (!type) {
        return false;
      }

      const methodsUsingReceipt = ['EDC'];

      return methodsUsingReceipt.includes(type.name);
    },
    checkIsMethodDisabled(type) {
      const methodsUsingBank = ['Transfer Bank', 'EDC'];

      if (!methodsUsingBank.includes(type)) {
        return false;
      }

      return (
        this.getBanksByOffice.data.filter(
          (bank) => bank.attributes.office_bank_type === 'bank'
        ).length < 1
      );
    },
    getPayload(paymentMethod) {
      const payload = {
        attributes: {
          payment_amount: paymentMethod.value,
          transfer_date: dayjs(paymentMethod.transfer_date).format(),
        },
        relationships: {
          'office-bank': {
            data: {
              type: 'office-banks',
              id: null,
            },
          },
          'payment-method-type': {
            data: {
              type: 'payment-method-types',
              id: paymentMethod.type.value,
            },
          },
        },
      };

      if (paymentMethod.type.name === 'Cash') {
        payload.relationships['office-bank'].data.id =
          this.getBanksByOffice.data.find(
            (bank) => bank.attributes.office_bank_type === 'cash'
          ).id;
      }

      if (paymentMethod.type.name === 'EDC') {
        payload.attributes.balance_used = 'edc';
      }

      if (this.checkIsMethodUsingBank(paymentMethod.type)) {
        payload.relationships['office-bank'].data.id = paymentMethod.bank.id;
      }

      if (this.checkIsMethodUsingReceiptNumber(paymentMethod.type)) {
        payload.attributes.receipt_number = paymentMethod.receipt_number;
      }

      return payload;
    },
    pushEmptyPaymentDetailsItem() {
      this.paymentMethodsForm.push({
        type: null,
        bank: null,
        receipt_number: null,
        description: null,
        files: [],
        id: null,
        value: null,
      });
    },
    setPaymentDetails() {
      const orderPaymentMethods = this.getPaymentMethods.data.filter(
        (paymentMethod) => paymentMethod.relationships['office-bank'].data
      );
      if (!orderPaymentMethods.length) {
        this.pushEmptyPaymentDetailsItem();
      } else {
        this.paymentMethodsForm = orderPaymentMethods.map((paymentMethod) => {
          const bank = this.getSingleIncluded(
            this.getPaymentMethods,
            paymentMethod.relationships['office-bank'].data.id
          );

          return {
            id: paymentMethod.id,
            type: this.paymentMethodTypes.find(
              (type) =>
                type.name === paymentMethod.attributes.payment_method_type
            ),
            bank: {
              id: bank.id,
              name: bank.attributes.name,
            },
            value: paymentMethod.attributes.payment_amount,
            receipt_number: paymentMethod.attributes.receipt_number,
            files: [],
            transfer_date: paymentMethod.attributes.transfer_date,
            description: null,
          };
        });
      }
    },
  },
};
